window.addEventListener('load', function () {
    var constructionWork = new Vue({
        el: '#formConstructionWork',
        data: {
            sent: false,
            errors: '',
            formName: {
                name: 'formConstructionWork',
                description: 'Заявка на страхование строительно-монтажных рисков'
            },
            form: {
                organizationName: {
                    "f-label": "Наименование компании *", 
                    "f-type": "text",
                    "f-condition": true,
                    "f-value": "",
                    "f-placeholder": "",
                    "f-required": true,
                    "f-style": {}
                },
                delegateName: {
                    "f-label": "ФИО представителя",
                    "f-type": "text",
                    "f-condition": true,
                    "f-value": "",
                    "f-placeholder": "",
                    "f-required": false,
                    "f-style": {}
                },
                phone: {
                    "f-label": "Введите номер телефона для обратной связи *",
                    "f-type": "phone",
                    "f-condition": true,
                    "f-value": "",
                    "f-required": true,
                    "f-style": {},
                    "f-regexp": "^\\+7\\(\\d{3}\\) \\d{3}-\\d{2}-\\d{2}$"
                },
                email: {
                    "f-label": "Введите эл.почту для обратной связи *",
                    "f-type": "email",
                    "f-condition": true,
                    "f-value": "",
                    "f-required": true,
                    "f-style": {}
                },
                constructionKind: {
                    "f-label": "Вид строительства",
                    "f-type": "select",
                    "f-condition": true,
                    "f-value": "",
                    "f-dic": "constructionKindDic",
                    "f-plain-value" : "",
                    "f-required": false,
                    "f-style": {}
                },
                industrialKind: {
                    "f-label": "Вид промышленного строительства",
                    "f-type": "select",
                    "f-condition": false,
                    "f-value": "",
                    "f-dic": "industrialKindDic",
                    "f-plain-value" : "",
                    "f-required": false,
                    "f-style": {}
                },
                industrialKindComment: {
                    "f-label": "Дополнительная информация",
                    "f-type": "text",
                    "f-condition": false,
                    "f-value": "",
                    "f-placeholder": "Добавьте дополнительное описание при необходимости...",
                    "f-required": false,
                    "f-style": {}
                },
                totalCost: {
                    "f-label": "Общая стоимость строительно-монтажных работ, (млн. руб. без НДС)",
                    "f-type": "text",
                    "f-condition": true,
                    "f-value": "",
                    "f-required": false,
                    "f-style": {}
                },
                location: {
                    "f-label": "Месторасположение объекта строительства",
                    "f-type": "text",
                    "f-condition": true,
                    "f-value": "",
                    "f-placeholder": "",
                    "f-required": false,
                    "f-style": {}
                },
                organizationRole: {
                    "f-label": "Ваша компания является",
                    "f-type": "select",
                    "f-condition": true,
                    "f-value": "",
                    "f-dic": "organizationRoleDic",
                    "f-plain-value" : "",
                    "f-required": false,
                    "f-style": {}
                },
                subcontractLimit: {
                    "f-label": "Лимит ответственности субподрядчика, (млн. руб. без НДС)",
                    "f-type": "text",
                    "f-condition": false,
                    "f-value": "",
                    "f-required": false,
                    "f-style": {}
                },
                needPublicLiability: {
                    "f-label": "Требуется страхование гражданской ответственности",
                    "f-type": "switch",
                    "f-condition": true,
                    "f-value": false,
                    "f-required": false,
                    "f-style": {}
                },
                publicLiabilityLimit: {
                    "f-label": "Лимит ответственности (млн.рублей)",
                    "f-type": "text",
                    "f-condition": false,
                    "f-value": "",
                    "f-required": false,
                    "f-style": {}
                },
                needAfterGuaranty: {
                    "f-label": "Требуется страхование постгарантийных обязательств",
                    "f-type": "switch",
                    "f-condition": true,
                    "f-value": false,
                    "f-required": false,
                    "f-style": {}
                },
                afterGuarantyPeriod: {
                    "f-label": "Срок страхования постгарантийных обязательств (месяцев)",
                    "f-type": "text",
                    "f-condition": false,
                    "f-value": "",
                    "f-placeholder": "",
                    "f-required": false,
                    "f-style": {}
                }
            },
            dictionaries: {
                constructionKindDic: [
                    {name: "living", description: "жилое строительство"},
                    {name: "industrial", description: "промышленное строительство"}
                ],
                industrialKindDic: [
                    {name: "energy", description: "объекты энергетики"},
                    {name: "mining", description: "объекты добычи полезных ископаемых"},
                    {name: "metallurgic", description: "металлургическая промышленность"},
                    {name: "other", description: "иное"},
                ],
                organizationRoleDic: [
                    {name: "customer", description: "заказчиком строительства"},
                    {name: "general-contractor", description: "генеральным подрядчиком"},
                    {name: "sub-contractor", description: "субподрядчиком"}
                ]
            }
        },
        methods: {
            switchId(fName) {
                return this.formName.name + '-' + fName;
            },
            refreshForm() {
                for (var key in this.form) {
                    this.form[key]['f-value'] = '';
                }
            },
            onSubmit(evt) {
                evt.preventDefault();

                this.errors = "";
                let errorsCnt = validateForm(this.form);
                if (errorsCnt > 0) {
                    this.errors = "Необходимо корректно заполнить все поля, отмеченные звездочкой (*)";
                }

                if (errorsCnt == 0) {
                
                    let mailMessage = constructMailMessage(this.form, this.formName.description);

                    const emailOptions = {
                        method: 'POST',
                        headers: { 'content-type': 'application/json' },
                        data: {msg: mailMessage},
                        url: '/local/form/submit-msg-form.php',
                    };

                    this.$axios(emailOptions)
                        .then(res => { 
                            // console.log('msg was sent ' + mailMessage);
                            this.sent = true; 
                            this.refreshForm();
                            $.fancybox.close({src: '#' + this.form.name});
                            $.fancybox.open('<div  id="thanks" class="popup ths"> <div class="ths-box"> <div class="ths-marg"> <p class="ths-t1">заявка принята</p> <p class="ths-t2">Мы свяжемся с вами <br>в ближайшее время</p> </div> </div> </div>');
                        })
                        .catch(function (error) {
                            console.log(error);
                            $.fancybox.open('<div class="message"><h2>Ошибка</h2></div>');
                        });
                }
            }            
        },
        computed: {
            isIndustrial() {
                return this.form.constructionKind['f-value'] === 'industrial';
            },
            isAfterGuaranty: function() {
                console.log('isAfterGuaranty is called');
                return this.form.afterGuarantyPeriod['f-value'];
            },
            watchConstructionKind() {
                return this.form.constructionKind['f-value'];
            },
            watchIndustrialKind() {
                return this.form.industrialKind['f-value'];
            },
            watchOrganizationRole() {
                return this.form.organizationRole['f-value'];
            },
            watchNeedPublicLiability() {
                return this.form.needPublicLiability['f-value'];
            },
            watchNeedAfterGuaranty() {
                return this.form.needAfterGuaranty['f-value'];
            }
        },
        watch: {
            watchConstructionKind: function() {
                var vm = this;
                try {
                    this.form.industrialKind['f-value'] = '';
                    this.form.industrialKind['f-condition'] = this.isIndustrial;
                    this.form.industrialKindComment['f-condition'] = this.isIndustrial;
                    this.form.constructionKind['f-plain-value'] = this.dictionaries[this.form.constructionKind['f-dic']] ? 
                        this.dictionaries[this.form.constructionKind['f-dic']].find(function(dic){
                            return dic.name === vm.form.constructionKind['f-value'] ;
                        }).description : '';
                } catch(ignoredException) {}
            },
            watchIndustrialKind: function() {
                var vm = this;
                try {
                    this.form.industrialKindComment['f-value'] = '';
                    this.form.industrialKind['f-plain-value'] = this.dictionaries[this.form.industrialKind['f-dic']] ? 
                        this.dictionaries[this.form.industrialKind['f-dic']].find(function(dic){
                            return dic.name === vm.form.industrialKind['f-value'] ;
                        }).description : '';
                } catch(ignoredException) {}
            },
            watchOrganizationRole: function() {
                var vm = this;
                try {
                    this.form.subcontractLimit['f-condition'] = this.form.organizationRole['f-value'] === 'sub-contractor';
                    this.form.subcontractLimit['f-value'] = '';
                    this.form.organizationRole['f-plain-value'] = this.dictionaries[this.form.organizationRole['f-dic']] ? 
                        this.dictionaries[this.form.organizationRole['f-dic']].find(function(dic){
                            return dic.name === vm.form.organizationRole['f-value'] ;
                        }).description : '';
                } catch(ignoredException) {}
            },
            watchNeedPublicLiability: function() {
                this.form.publicLiabilityLimit['f-condition'] = this.form.needPublicLiability['f-value'];
                this.form.publicLiabilityLimit['f-value'] = '';
            },
            watchNeedAfterGuaranty: function() {
                this.form.afterGuarantyPeriod['f-condition'] = this.form.needAfterGuaranty['f-value'];
                this.form.afterGuarantyPeriod['f-value'] = '';
            }
        }
    });
})